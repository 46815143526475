<template>
  <KioskSlide title="Building Half Hourly Usage" :sub-title="`Half hourly usage at ${kiosk.asset.siteName}`" :hide="hideSlide">
    <div class="p-5 border-bottom">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Yesterday's Usage</div>
        </div>
        <div class="col">
          <AssetHalfHourlyConsumption style="height: 325px" :period="hhGraph.period" :data="consumption.current.data" units="kWh" />
        </div>
      </div>
    </div>
    <div class="p-5">
      <Spinner v-if="loading || loadingAction.getConsumption" />
      <div v-else class="row">
        <div class="col-md-5">
          <div class="font-w600 font-size-h2">Week Usage</div>
        </div>
        <div class="col">
          <AssetHalfHourlyConsumption style="height: 325px" :period="hhGraph.period" :data="consumption.current.data" units="kWh" />
        </div>
      </div>
    </div>
  </KioskSlide>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinnerLogo';
import KioskSlide from './KioskSlide';
import AssetHalfHourlyConsumption from '@/views/Machine/templates/charts/AssetHalfHourlyConsumption';

export default {
  name: 'KioskEnergy',
  components: {
    AssetHalfHourlyConsumption,
    KioskSlide,
    Spinner
  },
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    hideSlide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      yesterday: moment().subtract(1, 'days').startOf('day')
    };
  },
  computed: {
    ...mapGetters({
      kiosk: 'kioskLive/kiosk',
      asset: 'kioskLive/asset',
      consumption: 'kioskLive/consumption',
      loadingAction: 'kioskLive/loadingAction',
      loading: 'kioskLive/loading'
    }),
    hhGraph() {
      return {
        period: {
          startDate: moment().subtract(2, 'days').startOf('day'),
          endDate: this.yesterday
        }
      };
    }
  },
  methods: {
    percentageDiff(usage, prevUsage) {
      if (prevUsage === 0 && usage !== 0) return 100;
      if (prevUsage === 0 && usage === 0) return 0;
      const pctDiff = (usage - prevUsage) / prevUsage;

      return Math.round(pctDiff * 100 * 10) / 10;
    }
  }
};
</script>
<style lang="scss"></style>
