var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('KioskSlide', {
    attrs: {
      "title": "Building Half Hourly Usage",
      "sub-title": "Half hourly usage at ".concat(_vm.kiosk.asset.siteName),
      "hide": _vm.hideSlide
    }
  }, [_c('div', {
    staticClass: "p-5 border-bottom"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Yesterday's Usage")])]), _c('div', {
    staticClass: "col"
  }, [_c('AssetHalfHourlyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.hhGraph.period,
      "data": _vm.consumption.current.data,
      "units": "kWh"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "p-5"
  }, [_vm.loading || _vm.loadingAction.getConsumption ? _c('Spinner') : _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('div', {
    staticClass: "font-w600 font-size-h2"
  }, [_vm._v("Week Usage")])]), _c('div', {
    staticClass: "col"
  }, [_c('AssetHalfHourlyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.hhGraph.period,
      "data": _vm.consumption.current.data,
      "units": "kWh"
    }
  })], 1)])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }